<template>
  <div>
    <v-dialog
      v-if="drawer.searchDialog && isSearch"
      v-model="drawer.searchDialog"
      :transition="false"
      :max-width="340"
    >
      <v-card>
        <div v-if="!isLonLat">
          <div class="inputDiv d-flex">
            <v-icon
              style="color: grey;"
              class="ml-2 mr-1"
            >
              mdi-magnify
            </v-icon>
            <input
              ref="autocomplete"
              :placeholder="$t('Sidebar.search')+'...'"
              class="search-location"
              onfocus="value = ''"
              type="text"
              @click="autocompleteInit()"
            >
          </div>
          <v-btn
            block
            color="green"
            dark
            class="mt-3"
            @click="moveToCoordinates()"
          >
            {{ $t('Sidebar.search') }}
          </v-btn>
        </div>
        <div v-else>
          <!-- <v-switch
            v-model="is4326"
            :label="`Projection: ${is4326 ? '4326' : '3857'}`"
            class="my-0"
            color="green"
            dark
          /> -->
          <v-text-field
            v-model="latitude"
            solo
            clearable
            hide-details
            color="black"
            :label="$t('Sidebar.lat')"
          />
          <v-text-field
            v-model="longitude"
            solo
            clearable
            hide-details
            class="my-3"
            color="black"
            :label="$t('Sidebar.lon')"
          />
          <v-btn
            block
            color="green"
            dark
            @click="moveToCoordinates()"
          >
            {{ $t('Sidebar.search') }}
          </v-btn>
        </div>
        <v-divider class="my-3" />
        <v-btn
          color="green darken-1"
          text
          block
          @click="isLonLat=!isLonLat"
        >
          <div v-if="!isLonLat">
            {{ $t('Sidebar.exactCoordinates') }}
            <v-icon small>
              mdi-map-marker
            </v-icon>
          </div>
          <div v-else>
            {{ $t('Sidebar.notExactCoordinates') }}
            <v-icon small>
              mdi-magnify
            </v-icon>
          </div>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog
      v-else
      v-model="drawer.globalLayersDialog"
      :transition="false"
      :max-width="mobileBreakpoints() ? 250 : 340"
    >
      <global-layer />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { fromLonLat } from 'ol/proj';
import { getFormattedDate } from '@/utils.js';

export default {
  components: {
    GlobalLayer: () => import('@/components/layout/GlobalLayer')
  },
  props: {
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      is4326: true,
      autocomplete: null,
      isLonLat: false,
      latitude: '',
      longitude: '',
      searchedPlace: ''
    };
  },
  computed: {
    ...mapState(['drawer', 'map', 'app_user'])
  },
  watch: {
    $route() {}
  },
  methods: {
    ...mapActions(['newMapCenter', 'setRasterMarkerValue']),
    isLandscape() {
      return /Mobi|Android/i.test(navigator.userAgent) && window.innerHeight < window.innerWidth;
    },
    mobileBreakpoints() {
      return /Mobi|Android/i.test(navigator.userAgent) || this.isLandscape();
    },
    moveToCoordinates() {
      this.map.sendCenterEvent = false;
      this.map.sendClickEvent = false;

      if (this.isLonLat && this.longitude !== '' && this.latitude !== '') {
        this.drawer.searchDialog = !this.drawer.searchDialog;
        // if (this.is4326) {
        this.newMapCenter(fromLonLat([this.longitude, this.latitude], 'EPSG:3857'));
        // } else {
        //   this.newMapCenter([this.longitude, this.latitude]);
        // }
      } else {
        if (!this.isLonLat && this.$refs.autocomplete.value.length > 0) {
          this.drawer.searchDialog = !this.drawer.searchDialog;
          this.newMapCenter(fromLonLat([this.map.longitude, this.map.latitude], 'EPSG:3857'));
        }
      }

      this.setRasterMarkerValue();
      this.$gtag.event('Località ricercata', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id}`,
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `${this.searchedPlace} - Lat ${this.map.coordsLonLat[1]} - Lon ${this.map.coordsLonLat[0]}`
      });
    },
    autocompleteInit() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { types: ['geocode'] }
      );

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();

        // this.map.address = place.formatted_address;
        this.map.latitude = place.geometry.location.lat();
        this.map.longitude = place.geometry.location.lng();

        this.searchedPlace = '';
        place.address_components.forEach(item => {
          if (item.types[0] === 'administrative_area_level_3') {
            this.searchedPlace += item.short_name;
          }

          if (this.searchedPlace !== '' && item.types[0] === 'administrative_area_level_2') {
            this.searchedPlace += ` (${item.short_name})`;
          }
        });

        if (this.searchedPlace === '') {
          if (place.address_components[0].types[0] === 'plus_code') {
            const toRemove = place.address_components[0].long_name;
            this.searchedPlace = place.formatted_address.replace(toRemove, '');
          } else {
            this.searchedPlace = place.formatted_address;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.v-divider {
  border-color: #eee;
}
.v-dialog__content {
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 125px;
}

@media (max-device-width:599px), (max-device-height:599px) and (orientation: landscape){
  .v-dialog__content {
     padding-left: 0px;
     justify-content: center;
  }
  ::v-deep .v-dialog.v-dialog--active {
    max-height: calc(100% - 48px)!important;
  }
}
.v-btn {
  text-transform: none;
}
.v-card {
  background-color: black;
  color: white;
  padding: 10px;
}
.search-location {
  font-size:16px;
  color: black;
  background-color: white;
  width: 88%;
  height: 48px;
  border: 0;
}
.search-location:focus {
  outline: none;
}
.inputDiv {
  background-color: white;
  border-radius: 5px;
}
</style>

<style module>
  .v-dialog--active {
    max-height: calc(100% - 48px)!important;
  }
</style>
